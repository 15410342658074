import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {SalWrapper} from "react-sal";
import './busqueda.css';

const EmpleosDetail = ({data}) => {

    return <SalWrapper>
        <section className="fullwidth pb-0 seccion-empleo">
            <Container>
                <Row className="pb-5 paso-1">
                    <p className="volanta"
                       data-sal="fade"
                       data-sal-delay="100"
                       data-sal-duration="200"
                    >BÚSQUEDA {data.id_search}</p>
                    <h3
                        data-sal="fade"
                        data-sal-delay="200"
                        data-sal-duration="200"
                    >{data.position_name}</h3>
                </Row>
            </Container>

            <div className="bg-lt-grey empleo-information-section"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="py-4 mb-5">
                        <Col sm={12} md={4} className='card-information'>
                            <h4 className="mb-0"><strong>Sector</strong></h4>
                            <p className="base-text mb-0">
                                {data.sector}
                            </p>
                        </Col>
                        <Col sm={12} md={4} className='card-information'>
                            <h4 className="mb-0"><strong>Nombre del puesto</strong></h4>
                            <p className="base-text mb-0">
                                {data.position_name}
                            </p>
                        </Col>
                        <Col sm={12} md={4} className='card-information'>
                            <h4 className="mb-0"><strong>Seniority</strong></h4>
                            <p className="base-text mb-0">
                                {data.seniority_name}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <h4 className="mt-5 primary-color"
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="200"
                >Descripción de las tareas a realizar:</h4>
                <p className="base-text"
                   data-sal="fade"
                   data-sal-delay="100"
                   data-sal-duration="200"
                >
                    {data.description_task}
                </p>

                <Button target="_blank" href={process.env.GATSBY_RRHH_SEARCH_URL + 'aplicar/' + data.id_search + '?origen=Sitio corporativo Balanz'}
                        type="button"
                        className='my-5' variant="secondary">Postular</Button>
            </Container>
        </section>
    </SalWrapper>

}

export default EmpleosDetail;